import { OauthService, PlayerService } from "./api.service";
import { useSignStore } from "../store/sign";

export default {
    async signIn(username: string, password: string) {
        const signStore = useSignStore();
        signStore.loading = true;
        signStore.error = undefined;

        return OauthService.getToken(username, password)
            .then(() => {
                signStore.authenticated = true;
            })
            .catch((error) => {
                signStore.error = error;
            })
            .finally(() => {
                signStore.loading = false;
            });
    },

    async signUp(username: string, nickname: string, password: string, gamePassword: string) {
        const signStore = useSignStore();
        signStore.loading = true;
        signStore.error = undefined;

        return PlayerService.register(username, nickname, password, gamePassword)
            .then((response) => {
                this.signIn(username, password);
            })
            .catch((error) => {
                signStore.error = error;
            })
            .finally(() => {
                signStore.loading = false;
            });
    },

    async signOut() {
        const signStore = useSignStore();
        signStore.loading = true;
        signStore.error = undefined;

        return OauthService.clearToken()
            .then(() => {
                signStore.authenticated = false;
            })
            .finally(() => {
                signStore.loading = false;
            });
    }
}