import { defineStore } from "pinia";

interface State {
    authenticated?: boolean,
    loading?: boolean,
    error?: any,
}

// --------------------------------------------
// ---------- DO NOT USE API SERVICE ----------
// ----- IT MAY CAUSE INFINITE LOOP ERROR -----
// --------------------------------------------

export const useSignStore = defineStore('sign', {
    state: () : State => ({
        authenticated: undefined,
        loading: false,
        error: undefined,
    }),
})