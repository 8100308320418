import { defineStore } from "pinia";
import { BuildingService } from "../../common/api.service";
import L from "leaflet";
import {Building, BuildingType, Resource, ResourceRegion} from "../../type";

export interface BuildingState {
    currentType?: BuildingType;

    types?: Array<BuildingType>;
    typesLoading: boolean;
    typesError: boolean;

    buildings?: Array<Building>;
    buildingsLoading: boolean;
    buildingsError: boolean;

    resourcesInRange?: Array<{distance:number, amounts: Array<Resource>, resource_region: ResourceRegion}>;
    resourcesInRangeLoading: boolean;
    resourcesInRangeError: boolean;
}

export const useBuildingStore = defineStore('building', {
    state: () : BuildingState => ({
        currentType: undefined,

        types: undefined,
        typesLoading: false,
        typesError: false,

        buildings: undefined,
        buildingsLoading: false,
        buildingsError: false,

        resourcesInRange: undefined,
        resourcesInRangeLoading: false,
        resourcesInRangeError: false,
    }),
    getters: {
        findBuilding(state: BuildingState) {
            return (id: string) => state.buildings && state.buildings.find((building) => building.id === id);
        },

        findType(state: BuildingState) {
            return (slug: string) => state.types.find((type) => type.slug === slug);
        },
    },
    actions: {
        fetchTypes() {
            this.types = undefined;

            this.loading = true;
            this.error = null;

            BuildingService.getBuildingTypes()
                .then((response) => {
                    this.types = response && response.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.error = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        fetchBuildings() {
            this.buildingsLoading = true;
            this.buildingsError = false;
            this.buildings = undefined;

            BuildingService.getBuildings()
                .then((response) => {
                    this.buildings = response && response.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.error = true;
                })
                .finally(() => {
                    this.buildingsLoading = false;
                });
        },

        fetchResourcesInRange(buildingType: BuildingType) {
            this.resourcesInRangeLoading = true;
            this.resourcesInRangeError = null;
            this.resourcesInRange = undefined;

            BuildingService.getResourcesInRange(buildingType.slug)
                .then((response) => {
                    this.resourcesInRange = response && response.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.resourcesInRangeError = error;
                })
                .finally(() => {
                    this.resourcesInRangeLoading = false;
                });
        },

        build(type: BuildingType, name: string, position: L.LatLng, options: Object = {}) {
            this.buildingsLoading = true;
            this.buildingsError = null;

            BuildingService.addBuilding(type.slug, name, position, options)
                .then((response) => {
                    this.buildings.push(response && response.data);
                })
                .catch((error) => {
                    console.log('Error adding building', error);
                    this.buildingsError = error;
                })
                .finally(() => {
                    this.buildingsLoading = false;
                });
        },

        pickUpResources(building: Building) {
            BuildingService.pickUpResources(building.id)
                .then((response) => {
                    const updatedBuilding = response && response.data;
                    building.stock = updatedBuilding.stock;
                })
                .catch((error) => {
                    console.log('Error picking up resources', error);
                    this.error = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        changeCurrentType(type: BuildingType|string) {
            if (typeof type === 'string') {
                type = this.findType(type);
            }
            this.currentType = type;
        },

        resetCurrentType() {
            this.currentType = undefined;
        },

    },
})